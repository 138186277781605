export const greetingMessage = (firstName: string): string => {
  let msg = "";
  const day = new Date();
  const hr = day.getHours();
  if (hr >= 0 && hr <= 12) {
    msg = `Good Morning, ${firstName}!`;
  } else if (hr > 12 && hr <= 18) {
    msg = `Good Afternoon, ${firstName}!`;
  } else if (hr > 18) {
    msg = `Good Evening, ${firstName}!`;
  }
  return msg;
};

export function addCommas(num: number) {
  // Convert the number to a string
  let numStr = num.toString();

  // Check if the number is greater than 1000
  if (num >= 1000) {
    // Split the string into parts before and after the decimal point
    const parts = numStr.split(".");

    // Add commas to the whole number part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Rejoin the parts into a single string
    numStr = parts.join(".");
  }

  // Return the modified string
  return numStr;
}

export function getTodayDate() {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const dd = String(today.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

export function getFormattedDate(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long", // e.g., Wednesday
    year: "numeric", // e.g., 2024
    month: "long", // e.g., March
    day: "numeric", // e.g., 8
    hour: "numeric", // e.g., 4
    minute: "numeric", // e.g., 30
    hour12: true, // Use 12-hour clock format
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
}

export function stripLink(text: string) {
  const regex = /<<([^:]*):[^>]*>>/g;
  return text.replace(regex, "$1");
}

export function camelCaseToTitle(text: string) {
  // Split the text at uppercase letters and join with a space
  const withSpaces = text.replace(/([A-Z])/g, " $1");

  // Capitalize the first letter of each word
  const titleCase = withSpaces
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return titleCase;
}
