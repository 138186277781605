import { WEEKDAYS, months } from "@/app/_consts/internal";
import { ColumnForTable } from "@/app/_types";

export const getToken = async () => localStorage.getItem("access_token");

export const getHeader = async () => {
  const token = await getToken();
  return {
    headers: {
      Authorization: token,
    },
  };
};

// Returns a random integer from 1 to n:
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const randomNumber = (n: number | Array<any>): number => {
  if (typeof n === "number") {
    return Math.floor(Math.random() * n) + 1;
  } else {
    const index = Math.floor(Math.random() * n.length);
    return n[index];
  }
};

// Get days for the current week
// https://stackoverflow.com/questions/71179131/how-do-i-get-an-array-of-all-days-in-the-week-given-the-current-date-in-javascri
export const selectWeek = (date: Date) => {
  return Array(7)
    .fill(new Date(date))
    .map((el, idx) => {
      const date = new Date(el.setDate(el.getDate() - el.getDay() + idx));
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const day = `${date.getDate()}`.padStart(2, "0");
      const dateStr = `${date.getFullYear()}-${month}-${day}`;
      return {
        date: dateStr,
        weekday: WEEKDAYS[date.getDay()],
      };
    });
};

// Random number generator with seed mulberry32
// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
export function randomNumberGeneratorWithSeed(seed: number) {
  return function () {
    let t = (seed += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

// https://stackoverflow.com/questions/8780529/how-to-refresh-page-on-specific-day-at-specific-time-in-specific-time-zone
export function reloadAt(
  hours: number,
  minutes: number,
  seconds: number,
): void {
  const now = new Date();
  const then = new Date();

  if (
    now.getUTCHours() > hours ||
    (now.getUTCHours() == hours && now.getUTCMinutes() > minutes) ||
    (now.getUTCHours() == hours &&
      now.getUTCMinutes() == minutes &&
      now.getUTCSeconds() >= seconds)
  ) {
    then.setUTCDate(now.getUTCDate() + 1);
  }
  then.setUTCHours(hours);
  then.setUTCMinutes(minutes);
  then.setUTCSeconds(seconds);

  const timeout = then.getTime() - now.getTime();
  setTimeout(function () {
    window.location.reload();
  }, timeout);
}

export function transformArrayForTable(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr: { [key: string]: any }[],
  titles: string[],
  flex: number[],
): ColumnForTable[] {
  if (!arr || !arr.length || !arr[0]) {
    return titles.map((title, index) => {
      return { title: title, data: [], flex: flex[index] };
    });
  }

  const keys = Object.keys(arr[0]);
  return keys.map((key, index) => {
    const values = arr.map((item) => item[key]);
    return { title: titles[index], data: values, flex: flex[index] };
  });
}

export function formatDate(inputDate: string): string {
  const dateObj = new Date(inputDate);
  const monthIndex = dateObj.getMonth();
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  const formattedMonth = getMonthName(monthIndex);
  const formattedDate = `${formattedMonth} ${day}, ${year}`;
  return formattedDate;
}

export function getMonthName(monthIndex: number) {
  return months[monthIndex].label;
}

export const getCurrentDateTime = () => {
  const currentDate = new Date();

  // Get the current local date in YYYY-MM-DD format
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");
  const currentDateString = `${year}-${month}-${day}`;

  // Get the current local time in HH:mm format
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const currentTimeString = `${hours}:${minutes}`;

  return { currentDateString, currentTimeString };
};

export function dailyCycleIndex(interval: number) {
  const startDate = new Date("2024-07-01");
  const currentDate = new Date();
  const daysDifference = Math.floor(
    (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
  );
  return daysDifference % interval;
}

export function updateNestedObject(
  originalObject: unknown,
  chapterDepth: string,
  updatedList: unknown,
) {
  const levels = chapterDepth.split("_");
  const updatedObject = JSON.parse(JSON.stringify(originalObject));

  if (levels.length === 1) {
    updatedObject.list[levels[0]] = updatedList;
  } else if (levels.length === 2) {
    if (!updatedObject.list[levels[0]].list) {
      updatedObject.list[levels[0]].list = [];
    }
    updatedObject.list[levels[0]].list[levels[1]] = updatedList;
  }
  return updatedObject;
}

export const getCurrentDate = () => {
  const date = new Date();
  const month = date.toLocaleString("en-US", { month: "long" });
  return `${
    month.charAt(0).toUpperCase() + month.slice(1)
  } ${date.getFullYear()}`;
};

const bar_colors = ["bg-[#009DB9]", "bg-[#B2D235]", "bg-[#FBAD18]"];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapItemToColors = (item: any, index: number) => {
  return {
    color: bar_colors[index % bar_colors.length],
    ...item,
  };
};

export function searchText(
  text: string,
  searchFor: string,
  includeContext: boolean = false,
): string | null {
  // Escape special regex characters in searchFor
  const escapedSearchFor = searchFor.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // The regex pattern to optionally capture context around the match
  const pattern = includeContext
    ? `(.{0,30}\\b${escapedSearchFor}\\S*.{0,30})` // Adjust the numbers (30) to change the context length
    : `\\b${escapedSearchFor}\\S*`;

  const regex = new RegExp(pattern, "gi");

  // Find the first match
  const match = regex.exec(text);

  if (match) {
    // If includeContext is true, return the first captured group (the whole match with context)
    // Otherwise, return the entire match (the matched word or phrase)
    return includeContext ? match[1].trim() : match[0];
  } else {
    return null; // No matches found
  }
}

export const isDevelopment = () => {
  return (
    window.location.hostname === "localhost" ||
    window.location.hostname.includes("dev.")
  );
};

export const isStaging = () => {
  return window.location.hostname.includes("stg.");
};
